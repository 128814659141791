import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

export const ContactTemplate = ({ data }) => {
  const { _rawContent, title } = data;

  return (
    <>
      <Wrapper>
        <Container>
          <h1> contact </h1>
          <h2> {title} </h2>
          <div>
            <BlockContent blocks={_rawContent} />
          </div>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;
  margin-left: 10%;
  padding: 25px;
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  box-sizing: border-box;
  min-height: 80vh;
  @media screen and (max-width: 800px) {
    margin: 40px 0;
  }
  @media screen and (max-width: 400px) {
    max-width: 100%;
  }

  h1 {
    color: var(--black);
    font-family: RalewayBold;
    font-weight: bold;
    font-size: 15px;
    line-height: 40px;
    text-transform: uppercase;
    width: 100%;
  }

  h2 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 50px;
  }

  p {
    font-size: 15px;
    line-height: 22px;
    color: var(--black);
    font-family: RalewayRegular;
    letter-spacing: 0.2px;
    font-weight: 400;
    margin: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
